export default {
  header: {
    navigation: {
      main: 'Главная',
      reports: 'Отчёты'
    },
    notVerifiedList: {
      tooltip: 'Камеры, требующие активации'
    }
  },
  noCameras: 'Нет камер',
  status: {
    camera: {
      ok: 'Штатно',
      problem: 'Неисправность',
      notworking: 'Статистика не собирается',
      unknown: 'Неизвестно'
    },
    exploitation: {
      introduced: 'Активирован | Активирован {date}',
      notVerified: 'Не активирован | Не активирован с {date}',
      notFilled: 'Не заполнен'
    }
  },
  buttons: {
    apply_reports: 'Создать отчет',
    apply_main: 'Применить',
    goToCameras: 'Перейти к камерам',
    createReport: 'Построить отчёт',
    select: 'Выбрать',
    selectAll: 'Выбрать все',
    resetAll: 'Сбросить все',
    edit: 'Редактировать',
    save: 'Сохранить',
    cancel: 'Отмена',
    refresh: 'Обновить',
    refreshList: 'Обновить список',
    yes: 'Да',
    no: 'Нет',
    ok: 'OK',
    export: 'Экспортировать отчет',
    confrimExport: 'Экспортировать',
    auth: 'Авторизоваться',
    clear: 'ОК',
    inExploitation: 'Начать сбор статистики',
    outExploitation: 'Прекратить сбор статистики',
    exit: 'Выход',
    stop_loading: 'Остановить',
    stop_export: 'Отменить экспорт',
    fillCameraPassports: 'Заполнить паспорта камер',
    close: 'Закрыть',
    openList: 'Раскрыть список',
    closeList: 'Свернуть список',
    detailReport: 'Детально по камерам'
  },
  placeholders: {
    login: 'Логин',
    password: 'Пароль',
    rangeOfIpCameras: 'Диапазон IP-адресов',
    statusCameras: 'Статус камеры',
    rangeOfIpServer: 'IP-адрес сервера',
    statusExpoitation: 'Статус паспорта камеры',
    search: 'Найти',
    searchCameraByNumber: 'Введите имя камеры',
    timeInterval: 'Выберите интервал',
    selectCamera: 'Выберите камеру',
    cameraSearch: 'Поиск камеры'
  },
  selectsOptions: {
    all: 'Все',
    selectAll: 'Выбрать все',
    timeInterval: {
      last_day: 'Последний день',
      last_week: 'Последняя неделя',
      last_month: 'Последний месяц',
      last_year: 'Последний год',
      choose: 'Выбрать интервал'
    }
  },
  table: {
    emptyState: 'Загрузка данных...',
    clearAllFilters: 'Очистить фильтры'
  },
  timeIntervalModal: {
    title: 'Временной интервал'
  },
  passportModal: {
    header: 'Параметры камеры',
    formHeader: 'Паспорт камеры',
    refresh: 'Обновить камеру'
  },
  saveWithoutChangesModal: {
    question: 'Вы уверены, что хотите закрыть без сохранения?',
    description: 'Изменения не вступят в силу'
  },
  saveModal: {
    title: 'Внимание!',
    description: 'Если вы измените параметры паспорта камеры, вся накопленная статистика по камере будет утеряна. Продолжить?'
  },
  fillPasportsModal: {
    title: 'Внимание!',
    description: 'Среди выбранных камер есть такие, по которым уже есть заполненный паспорт. Если вы продолжите, то параметры паспортов для таких камер будут изменены. Продолжить?'
  },
  checkIntroducedCamerasModal: {
    title: 'Внимание!',
    description: 'Среди выбранных камер есть такие, по которым уже пишется статистика. Если вы измените параметры паспорта камер по таким камерам, вся накопленная статистика по ним будет утеряна. Продолжить?'
  },
  emptyPassportModal: {
    title: 'Внимание!',
    description: 'Среди выбранных камер есть такие, по которым нет заполненного паспорта. Начать сбор статистики по таким камерам невозможно. Продолжить для остальных камер?'
  },
  ignoreIntroducedCamerasModal: {
    title: 'Внимание!',
    description: 'Среди выбранных камер есть такие, по которым уже пишется статистика. Данные камеры будут проигнорированы. Продолжить для остальных камер?'
  },
  groupPassportModal: {
    title: 'Параметры паспортов камер',
    cameras: 'Камеры'
  },
  loaderModal: {
    editPassports: 'Выполняется обновление паспортов камер',
    updateStatistic: 'Выполняется запуск сбора статистики по камерам'
  },
  limitSeparateReportModal: {
    title: 'Количество камер в отчете: {count}.',
    description: 'Индивидуальные отчеты будут экспортированы для первой {maxCount} камер.'
  },
  editPassportsResults: {
    title: 'Результат обновления параметров паспортов камер',
    total: 'Обработано паспортов',
    successful: 'Обновлено успешно',
    failed: 'Не обновлено'
  },
  editExploitationResults: {
    title: 'Результат запуска сбора статистики по камерам',
    total: 'Выбрано камер',
    ignored: 'Проигнорировано камер',
    successful: 'Начат сбор статистики',
    failed: 'Не начат сбор статистики'
  },
  outexploitation: {
    title: 'Внимание!',
    description: 'Если вы прекратите сбор статистики, вся накопленная статистика по камере будет утеряна. Продолжить?'
  },
  statistic: {
    totalCameras: 'Всего камер: {total}'
  },
  findedTotalCameras: 'Найдено камер: {total}',
  cameraPrams: {
    name: 'Название камеры',
    status: 'Статус камеры',
    status_exploitation: 'Статус паспорта камеры',
    ip: 'IP-адрес камеры',
    ip_server: 'IP-адрес сервера',
    passport: {
      fps: 'FPS',
      resolution: 'Разрешение, px',
      kbps: 'Битрейт, кбит/с'
    }
  },
  detailTableReport: {
    header: 'Детально по камерам',
    name: 'Название камеры',
    percent: 'Часы'
  },
  statisticsСommon: {
    titles: {
      notWorkingCameraCount: 'Количество камер, по которым не собирается статистика',
      totalTime: 'Требуемое количество часов записи по камерам за все время'
    }
  },
  chart: {
    titles: {
      problemTime: 'Время нахождения камер в режиме "Неисправность"',
      workingTime: 'Время нахождения камер в режиме "Штатно"',
      workingTimePercent: 'Время нахождения камер в режиме "Штатно" в процентах от требуемого времени',
      archiveTime: 'Общая длительность записанного видеоархива'
    },
    tootip: '{hours}ч {minutes}м',
    timeAxis: 'Длительность (час)',
    percentAxis: 'Длительность (в процентах)',
    dayAxis: 'Дни',
    forAllTime: 'За всё время',
    summaryTime: '{hours}ч {minutes}м',
    timeUnits: {
      millisecond: 'миллисекунды',
      second: 'секунды',
      minute: 'минуты',
      hour: 'часы',
      day: 'дни',
      week: 'недели',
      month: 'месяцы',
      quarter: 'кварталы',
      year: 'года'
    },
    emptyChart: 'Нет данных для отображения',
    detalizationEnd: 'Достигнут максимальный уровень масштабирования графика для выбранного временного периода',
    unavailableDetalization: 'Масштабирование текущего графика невозможно'
  },
  stubs: {
    startSearch: {
      title:'Параметры для создания отчета не заданы',
      description: 'Задайте параметры и нажмите на кнопку "Создать отчет"'
    },
    emptySearch: {
      title: 'По вашему запросу ничего не найдено',
      description: 'Попробуйте изменить настройки поиска и повторить'
    },
    noConnection: {
      title: 'Нет соединения',
      description: 'Проверьте соединение и обновите страницу'
    },
    cameraImg: {
      notAvailable: 'Изображение недоступно'
    }
  },
  separate: {
    reports: 'Дополнительно экспортировать отчеты по каждой камере'
  },
  commonReport: {
    cameraCount: 'Камеры ({count})',
    selectInterval: 'Интервал'
  },
  copyright: '© Intelligent Security Systems, {year}',
  fields: {
    login: 'Логин',
    password: 'Пароль',
    timeInterval: 'Интервал',
    startDate: 'Дата начала',
    endDate: 'Дата окончания'
  },
  validation: {
    required: 'Поле {_field_} обязательно для заполнения',
    valid_ip_range: 'Некорректный диапазон IP-адресов',
    invalid_date: 'Некорректная дата',
    not_future_date: 'Дата не должна быть в будущем',
    dates_not_match: 'Дата окончания должна быть минимум на час больше даты начала',
    invalid_time_interval: 'Разница между датой начала и датой окончания должна быть не более 5 лет',
    resolution: 'Должны быть заполнены ширина и высота'
  },
  serverErrors: {
    '500': 'Внутренняя ошибка Сервера',
    'time_out_error': 'Время выполнения запроса истекло'
  },
  dateTrainslate: {
    rtl: false,
    yearSuffix: '',
    ymd: false,
    language: 'Russian',
    days: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    monthsAbbr: ['Янв', 'Февр', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сент', 'Окт', 'Нояб', 'Дек']
  }
}